import { useEffect, useMemo, useState } from 'react';
import Validation from '../../../Constants/Validation';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  fetchCouponData,
  removeCoupon,
} from '../../../redux/features/Coupon/couponSlice';

import { BASE_URL_SANDBOX, USED_LP_POINTS } from '../../../Constants/Config';
import axios from 'axios';

const CouponTipSectionLogic = ({ merchantId }) => {
  const dispatch = useDispatch();

  const [orderSummaryData, setOrderSummaryData] = useState();
  const { filterCartData } = Validation();
  const location = useLocation();
  const [cart, setCart] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [storeproductData, setStoreProductData] = useState();
  const [singleStoreData, setSingleStoreData] = useState();
  const lp_sc_user_data = useSelector(
    (state) => state?.lpscPayment?.LpscPaymentData,
  );

  const [discountAmt, setdiscountAmt] = useState(0);
  const [totalTaxForlp, setTotalTaxForlp] = useState(0);
  const [percentageDeducLoy, setpercentageDeducLoy] = useState(0);
  const [percentageDeducCoupon, setpercentageDeducCoupon] = useState(0);
  const [amountDeducCoupon, setamountDeducCoupon] = useState(0);
  const [varientData, setVarientData] = useState([]);
  const [cartData, setCartAllData] = useState([]);
  const cartDataState = useSelector((state) => state?.cart?.cartData);
  const storeProductDataState = useSelector((state) => state?.storeProductData);
  const storeDataState = useSelector((state) => state?.singleStores);
  const VarientDataState = useSelector((state) => state?.variantCart);
  const taxDetailData = useSelector((state) => state?.taxDetail?.taxDetailData);
  const [taxValues, settaxValues] = useState(null);
  const AuthUserState = useSelector((state) => state?.authUser?.UserData);
  const [usedLoyaltyPointApiUserData, setusedLoyaltyPointApiUserData] =
    useState('');
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );
  const authUserData = useSelector((state) => state.authUser);

  const [values, setValues] = useState({
    coupon: '',
    loyaltyPoint: '',
    tip: '',
    tipcustom: '',
    storeCredit: '',
    usedLoyaltyPoint: '',
    usedStoreCredit: '',
    tipRemoved: false,
    errors: {
      coupon: '',
      loyaltyPoint:
        parseFloat(lp_sc_user_data?.loyalty_program?.min_points_redemption) >
        parseFloat(lp_sc_user_data?.customer_lp_point?.cus_loyality_points)
          ? 'You do not have enough points to use'
          : '',
      storeCredit: '',
      tip: '',
      tipcustom: '',
    },
    cursorposition: {
      coupon: 0,
      loyaltyPoint: 0,
      tip: 0,
      tipcustom: 0,
      storeCredit: 0,
    },
  });

  useEffect(() => {
    setCart(cartDataState);
  }, [cartDataState]);
  useEffect(() => {
    if (
      !storeProductDataState.loading &&
      !VarientDataState.loading &&
      !storeDataState.loading &&
      storeDataState &&
      storeDataState.storesData &&
      storeDataState.storesData.store_data
    ) {
      setStoreProductData(storeProductDataState.storeProductData);
      setVarientData(VarientDataState.variantCartData);
      setSingleStoreData(storeDataState.storesData.store_data);
    }
  }, [
    storeProductDataState.loading,
    storeDataState.loading,
    storeDataState.storesData,
    VarientDataState.loading,
    VarientDataState.variantCartData,
  ]);
  useEffect(() => {
    if (
      cartDataState &&
      VarientDataState?.variantCartData &&
      cartProductDetails
    ) {
      setCartAllData(
        filterCartData(cartDataState, varientData, cartProductDetails),
      );
    }
  }, [storeproductData, cartDataState, cart, varientData]);
  const calculateTotalPrice = async (cartData) => {
    const total = await cartData.reduce((acc, product) => {
      return parseFloat(acc) + parseFloat(product.price);
    }, 0);
    return setTotalPrice(total.toFixed(2));
    // return values.usedLoyaltyPoint !== ''
    // ? setTotalPrice(total - parseFloat(values.usedLoyaltyPoint).toFixed(2))
    // : setTotalPrice(total.toFixed(2));
  };
  useEffect(() => {
    if (cartData && taxDetailData) {
      CalcuLateTaxes(cartData, taxDetailData);
      calculateTotalPrice(cartData);
    }
  }, [cartData, percentageDeducLoy, percentageDeducCoupon, taxDetailData]);

  const isNumber = (amount) => {
    if (amount === '') return 0;
    if (amount === null || amount === undefined) return 0;
    if (isNaN(amount)) return 0;
    return amount ?? 0;
  };

  useEffect(() => {
    const totalLoyaltyPointEarnAmount =
      parseFloat(isNumber(totalPrice)) +
      parseFloat(isNumber(totalTaxForlp)) -
      parseFloat(isNumber(amountDeducCoupon));

    // 0 + 10 = 10...   10 - 10 = 0
    // amountDeducCoupon = 20

    // console.log('totalPrice: ', totalPrice);
    // console.log('totalTaxForlp: ', totalTaxForlp);
    // console.log(
    //   'usedLoyaltyPointApiUserData?.used_amt: ',
    //   usedLoyaltyPointApiUserData?.used_amt,
    // );
    // console.log('amountDeducCoupon: ', amountDeducCoupon);
    // console.log('values.usedStoreCredit: ', values.usedStoreCredit);
    // console.log('totalLoyaltyPointEarnAmount:', totalLoyaltyPointEarnAmount);

    // console.log('totalPrice: ', totalPrice);
    // console.log('totalTaxForlp: ', totalTaxForlp);
    // console.log('discountAmt: ', discountAmt);

    setOrderSummaryData({
      cartItems: Array.isArray(cartData) && cartData.length,
      cartProductSum: parseFloat(totalPrice),
      singleStoreData: singleStoreData,
      taxData: taxValues,
      taxDetailApiData: taxDetailData,
      lp_sc_data: {
        enable_loyalty: lp_sc_user_data?.loyalty_program?.enable_loyalty,
        store_credit_amt: values.usedStoreCredit,
        spend_loyality_amount: usedLoyaltyPointApiUserData?.used_amt,
        spend_loyality_points: usedLoyaltyPointApiUserData?.used_points,
        total_lp_apply_amt: (
          parseFloat(totalPrice) +
          parseFloat(totalTaxForlp) -
          parseFloat(discountAmt)
        ).toFixed(2),
        total_lp_earn_amt: totalLoyaltyPointEarnAmount.toFixed(2),
      },
      discountAmt: discountAmt,
      percentageDeducCoupon: percentageDeducCoupon ?? 0,
      percentageDeducLoy: percentageDeducLoy ?? 0,
    });
  }, [
    cartData,
    totalPrice,
    singleStoreData,
    taxValues,
    percentageDeducLoy,
    values.usedStoreCredit,
    discountAmt,
    totalTaxForlp,
  ]);

  const CalcuLateTaxes = (cartInfo, taxInfo) => {
    let tax = {};
    for (let index = 0; index < taxInfo?.length; index++) {
      const singleTax = taxInfo[index];
      for (let i = 0; i < cartInfo.length; i++) {
        const singleCart = cartInfo[i];
        if (singleCart?.taxesAplicable?.includes(singleTax.id)) {
          if (tax.hasOwnProperty(singleTax.title)) {
            tax[singleTax.title] =
              tax[singleTax.title] +
              (parseFloat(singleCart.price) * parseFloat(singleTax.percent)) /
                100;
          } else {
            tax[singleTax.title] =
              (parseFloat(singleCart.price) * parseFloat(singleTax.percent)) /
              100;
          }
        }
      }
    }

    let taxesCopy = { ...tax };

    // console.log('in tax percentageDeducCoupon: ', percentageDeducCoupon);

    if (percentageDeducCoupon > 0) {
      for (let taxType in tax) {
        // Deduct the percentage amount from the tax value
        tax[taxType] -= (taxesCopy[taxType] * percentageDeducCoupon) / 100;
      }
    }
    // Loop through each tax type in the tax object
    for (let taxType in tax) {
      // Deduct the percentage amount from the tax value
      if (
        parseFloat(percentageDeducCoupon) > 0 &&
        parseFloat(percentageDeducLoy) > 0
      ) {
        let newPercentDeduct =
          parseFloat(percentageDeducLoy) + parseFloat(percentageDeducCoupon);

        taxesCopy[taxType] -= (taxesCopy[taxType] * newPercentDeduct) / 100;
        tax[taxType] = taxesCopy[taxType];
      } else {
        tax[taxType] -= (taxesCopy[taxType] * percentageDeducLoy) / 100;
      }
    }

    let totalTax = 0;

    // Loop through each tax type in the tax object again to sum the amounts
    for (let taxType in tax) {
      // Accumulate the tax amount
      totalTax += tax[taxType];
    }

    // when user apply loyaltypoint then only this tax set
    setTotalTaxForlp(totalTax);
    const defaultTaxObject = taxInfo?.find((tax) => tax.title === 'DefaultTax');

    if (defaultTaxObject) {
      const confFee =
        parseFloat(storeDataState?.storesData?.store_data?.getConvFee) || 0;
      // console.log('ye hai conf fee: ', confFee);
      const temp = confFee * (parseFloat(defaultTaxObject.percent) / 100);

      const defaultTaxAmount = tax['DefaultTax'] ? tax['DefaultTax'] : 0;
      const newDefaultValue = defaultTaxAmount + temp;

      if (newDefaultValue) {
        tax['DefaultTax'] = newDefaultValue;
        settaxValues(tax);
      } else {
        let confeetaxVal =
          confFee * (parseFloat(defaultTaxObject.percent) / 100);
        tax['DefaultTax'] = confeetaxVal;
        settaxValues(tax);
      }
    }
    // console.log('here tax: ', tax);
  };

  const { validateCoupon, getCurrentDateTime } = Validation();
  const [couponData, setcouponData] = useState('');
  const couponDataState = useSelector((state) => state.coupon);

  useEffect(() => {
    if (couponDataState && !couponDataState.loading) {
      if (couponDataState?.errorMessage !== '') {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: couponDataState.errorMessage,
          },
        }));
      } else if (
        parseFloat(couponDataState?.couponData?.min_amount) >
        parseFloat(totalPrice)
      ) {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: `Minimum order amount should be $${parseFloat(
              couponDataState?.couponData?.min_amount,
            ).toFixed(2)}`,
          },
        }));
      } else {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: '',
          },
        }));

        // flag 0 - Percent Coupon & flag - 1 Amount coupon
        if (couponDataState?.couponData?.flag === '0') {
          // const couponAppliedAmt =
          //   parseFloat(totalPrice) -
          //   parseFloat(
          //     (parseFloat(totalPrice) *
          //       parseFloat(couponDataState?.couponData?.discount)) /
          //       100,
          //   );
          // console.log('couponAppliedAmt: ', couponAppliedAmt);

          // const couponAppliedPercent =
          //   100 - (couponAppliedAmt / parseFloat(totalPrice)) * 100;
          // console.log('couponAppliedPercent: ', couponAppliedPercent);
          // setpercentageDeducCoupon(couponAppliedPercent);
          // setamountDeducCoupon(parseFloat(totalPrice) - couponAppliedAmt);

          // ------------------- New Code
          const maximumDiscountValue =
            parseFloat(couponDataState?.couponData?.maximum_discount) > 0
              ? parseFloat(couponDataState?.couponData?.maximum_discount)
              : 0;

          const currentDiscountValue = parseFloat(
            (parseFloat(totalPrice) *
              parseFloat(couponDataState?.couponData?.discount)) /
              100,
          );

          // console.log('currentDiscountValue: ', currentDiscountValue);
          // console.log('maximumDiscountValue: ', maximumDiscountValue);

          if (
            currentDiscountValue > maximumDiscountValue &&
            maximumDiscountValue > 0
          ) {
            // dont allow to set the Main Percent keep according to the Maximum discount
            // console.log('AS PER MAX DISCOUNT....');
            const newPercentageDiscount =
              (parseFloat(maximumDiscountValue) / parseFloat(totalPrice)) * 100;
            setpercentageDeducCoupon(newPercentageDiscount);

            // console.log('inside 1 -------------------------');
            const deductCouponAmount =
              (parseFloat(totalPrice) * parseFloat(newPercentageDiscount)) /
              100;
            // console.log('newPercentageDiscount: ', newPercentageDiscount);
            // console.log('deductCouponAmount: ', deductCouponAmount);

            setamountDeducCoupon(parseFloat(deductCouponAmount));
          } else {
            // console.log('inside 2 -------------------------');
            const discountAmount =
              parseFloat(totalPrice) - currentDiscountValue;
            const couponAppliedPercent =
              100 - (discountAmount / parseFloat(totalPrice)) * 100;
            // console.log('heheheh couponAppliedPercent: ', couponAppliedPercent);
            setpercentageDeducCoupon(couponAppliedPercent);

            const deductCouponAmount =
              (parseFloat(totalPrice) * parseFloat(couponAppliedPercent)) / 100;
            // console.log('deductCouponAmount: ', deductCouponAmount);
            setamountDeducCoupon(parseFloat(deductCouponAmount));
          }
        } else {
          const couponAppliedAmt =
            parseFloat(totalPrice) -
            parseFloat(couponDataState?.couponData?.discount);

          const couponAppliedPercent =
            100 - (couponAppliedAmt / parseFloat(totalPrice)) * 100;
          setpercentageDeducCoupon(couponAppliedPercent);
          setamountDeducCoupon(parseFloat(totalPrice) - couponAppliedAmt);
        }

        setcouponData(couponDataState.couponData);
      }

      // else if (parseFloat(couponDataState?.couponData?.min_amount) < totalPrice) {

      //   setValues((previousState) => ({
      //     ...previousState,
      //     coupon: '',
      //     errors: {
      //       ...previousState.errors,
      //       coupon: "Minorder amount should be greater than total",
      //     },
      //   }));
      // }
    }
  }, [couponDataState, totalPrice]);

  const customerId = useMemo(() => {
    return authUserData.isAuthenticated &&
      authUserData.UserData &&
      authUserData.UserData.id
      ? authUserData.UserData.id
      : '';
  }, [authUserData]);
  const handleApplyCoupon = (setCouponValue) => {
    if (values.usedLoyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: 'Please remove loyality points for using a coupon code',
        },
      }));
    }
    if (values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: 'Please remove store credit for using a coupon code',
        },
      }));
    }
    setCouponValue(false);

    if (values.loyaltyPoint === '' && values.storeCredit === '') {
      const current_date_time = getCurrentDateTime();
      if (values.errors.coupon === '') {
        const data = {
          coupon: values.coupon,
          merchant_id: merchantId,
          current_date_time: current_date_time,
          customer_id: customerId,
        };
        dispatch(fetchCouponData(data));
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
        }));
      }
    }
    if (values.usedLoyaltyPoint !== '' && values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon:
            'Please remove loyality point and storeCredit points to add coupon code',
          storeCredit: '',
        },
      }));
    }
  };
  const handleRemoveCoupon = () => {
    dispatch(removeCoupon(''));
    setcouponData('');
    setValues((previousState) => ({
      ...previousState,
      coupon: '',
    }));
    setpercentageDeducCoupon(0);
  };
  const handleAddingTip = (mytip) => {
    setValues((previousState) => {
      return {
        ...previousState,
        tip: mytip,
        tipRemoved:
          mytip === '' &&
          !previousState.tipRemoved &&
          previousState.tipcustom === ''
            ? true
            : false,
      };
    });
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'coupon'
        ? event.target.value.toUpperCase()
        : fieldname === 'tipcustom'
        ? event.target.value
            .replace(/[^\d.]/g, '')
            .replace(/^(\d*\.)(.*)\./, '$1$2')
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2')
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'coupon':
        fieldValue.toUpperCase();
        cursorposition.coupon = newCursorPosition;
        validateCoupon(fieldValue, errors);
        break;
      case 'tip':
        cursorposition.coupon = newCursorPosition;
        validateCoupon(fieldValue, errors);
        break;
      case 'tipcustom':
        if (values.tipcustom === '') {
          values.errors.tipcustom = 'Please enter tip amount';
        } else {
          let inputStr = fieldValue.replace(/\D/g, ''); // Remove all non-digit characters for clean processing

          if (inputStr === '') {
            fieldValue = '0.00'; // Reset to default value if input is empty
          } else {
            // Remove leading zeros for clean numeric string
            inputStr = inputStr.replace(/^0+/, '');

            if (inputStr.length === 0) {
              fieldValue = '0.00';
            } else if (inputStr.length === 1) {
              fieldValue = '0.0' + inputStr;
            } else if (inputStr.length === 2) {
              fieldValue = '0.' + inputStr;
            } else {
              fieldValue =
                inputStr.slice(0, inputStr.length - 2) +
                '.' +
                inputStr.slice(-2);
            }
          }
        }
        cursorposition.tipcustom = event.target.selectionStart;
        break;

      default:
        break;
    }

    setValues((previousState) => ({
      errors,
      ...previousState,
      [fieldname]: fieldValue,
    }));
  };

  const handleStoreCredit = (event, grandtotal, storecredit, mytipamt) => {
    event.preventDefault();

    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'coupon'
        ? event.target.value.toUpperCase()
        : fieldname === 'tipcustom'
        ? event.target.value
            .replace(/[^\d.]/g, '')
            .replace(/^(\d*\.)(.*)\./, '$1$2')
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2')
        : fieldname === 'storeCredit'
        ? event.target.value
            // Remove extra dots and ensure only one dot exists at most
            .replace(/[^\d.]/g, '') // Allow digits and dots only
            .replace(/^(\d*\.)(.*)\./, '$1$2') // Remove extra dots
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2') // Remove extra dots after the decimal point
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'storeCredit':
        cursorposition.storeCredit = newCursorPosition;
        // --------------------------------------===
        let inputStr = fieldValue.replace(/\D/g, '');
        inputStr = inputStr.replace(/^0+/, '');

        if (inputStr.length === 0) {
          // fieldValue = "0.00";
        } else if (inputStr.length === 1) {
          fieldValue = '0.0' + inputStr;
        } else if (inputStr.length === 2) {
          fieldValue = '0.' + inputStr;
        } else {
          fieldValue =
            inputStr.slice(0, inputStr.length - 2) + '.' + inputStr.slice(-2);
        }
        if (
          parseFloat(fieldValue) > parseFloat(grandtotal - mytipamt).toFixed(2)
        ) {
          errors.storeCredit = `Store Credit can't be greater than $${parseFloat(
            grandtotal - mytipamt,
          ).toFixed(2)}`;
        } else if (fieldValue === '') {
          errors.storeCredit = '';
        } else if (parseFloat(fieldValue) > storecredit) {
          errors.storeCredit = "Credit can't be greater than your Store Credit";
        } else if (parseFloat(fieldValue) === 0) {
          errors.storeCredit = "Credit can't be 0.";
        } else {
          errors.storeCredit = '';
        }
        break;
      default:
        break;
    }

    setValues((previousState) => ({
      ...previousState,
      errors,
      [fieldname]: fieldValue,
    }));
    if (fieldValue === '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          loyaltyPoint: '',
        },
      }));
    }
  };

  const handleInputLoyaltyPoint = (
    event,
    subtotal,
    loyaltyPoint,
    couponDiscount,
    setCouponValue,
  ) => {
    event.preventDefault();

    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'loyaltyPoint'
        ? event.target.value
            // Remove extra dots and ensure only one dot exists at most
            .replace(/[^\d.]/g, '') // Allow digits and dots only
            .replace(/^(\d*\.)(.*)\./, '$1$2') // Remove extra dots
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2') // Remove extra dots after the decimal point
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'loyaltyPoint':
        cursorposition.loyaltyPoint = newCursorPosition;
        // --------------------------------------===
        let inputStr = fieldValue.replace(/\D/g, '');
        inputStr = inputStr.replace(/^0+/, '');

        if (inputStr.length === 0) {
          // fieldValue = "0.00";
        } else if (inputStr.length === 1) {
          fieldValue = '0.0' + inputStr;
        } else if (inputStr.length === 2) {
          fieldValue = '0.' + inputStr;
        } else {
          fieldValue =
            inputStr.slice(0, inputStr.length - 2) + '.' + inputStr.slice(-2);
        }
        // -----------------------------
        const newSubTotal = parseFloat(subtotal) - parseFloat(couponDiscount);

        if (parseFloat(fieldValue) > parseFloat(newSubTotal)) {
          errors.loyaltyPoint = `Loyalty Points can't be greater than ${parseFloat(
            newSubTotal,
          ).toFixed(2)}`;
        } else if (fieldValue === '') {
          errors.loyaltyPoint = '';
        } else if (parseFloat(fieldValue) > loyaltyPoint) {
          errors.loyaltyPoint =
            "Points can't be greater than your loyalty points.";
        } else if (parseFloat(fieldValue) === 0) {
          errors.loyaltyPoint = "Points can't be 0.";
        } else {
          errors.loyaltyPoint = '';
        }
        break;
      default:
        break;
    }

    setValues((previousState) => ({
      ...previousState,
      errors,
      [fieldname]: fieldValue,
    }));
    if (fieldValue === '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: '',
        },
      }));
      setCouponValue(true);
    }
  };
  const handleUseStoreCredit = () => {
    setValues((previousState) => ({
      ...previousState,
      usedStoreCredit: values.storeCredit,
    }));
    // setdiscountAmt(discountAmt + parseFloat(values.storeCredit));
  };

  const handleUseLoyaltyPoint = async () => {
    if (values.usedStoreCredit === '') {
      setValues((previousState) => ({
        ...previousState,
        usedLoyaltyPoint: parseFloat(values.loyaltyPoint).toFixed(2),
      }));
      try {
        const data = {
          merchant_id: merchantId,
          customer_id: AuthUserState?.id,
          used_loyality_amt: values.loyaltyPoint,
        };

        const response = await axios.post(
          BASE_URL_SANDBOX + USED_LP_POINTS,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (response.data.status === 200) {
          setusedLoyaltyPointApiUserData(response.data);
          const percentageDeducted = parseFloat(
            (values.loyaltyPoint / parseFloat(totalPrice)) * 100,
          ).toFixed(2);

          setpercentageDeducLoy(percentageDeducted);
          setdiscountAmt(discountAmt + parseFloat(values.loyaltyPoint));
        }
      } catch (error) {
        throw error;
      }
    }
    if (values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          loyaltyPoint:
            'To use loyality points first remove store credit used amount.',
          storeCredit: '',
        },
      }));
    }
  };

  const handleRemoveStoreCredit = (setCouponValue) => {
    setValues((previousState) => ({
      ...previousState,
      errors: { coupon: '' },
    }));
    if (values.coupon !== '') {
      setCouponValue(true);
    }

    setValues((previousState) => ({
      ...previousState,
      usedStoreCredit: '',
      storeCredit: '',
    }));
    if (values.errors.loyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: { loyaltyPoint: '' },
      }));
    }

    // setdiscountAmt(discountAmt - parseFloat(values.storeCredit));
  };

  const handleRemoveLoyaltyPoint = (setCouponValue) => {
    setValues((previousState) => ({
      ...previousState,
      errors: { coupon: '' },
    }));
    setValues((previousState) => ({
      ...previousState,
      usedLoyaltyPoint: '',
      loyaltyPoint: '',
    }));
    if (values.coupon !== '') {
      setCouponValue(true);
    }

    setpercentageDeducLoy(0);
    setusedLoyaltyPointApiUserData('');
    setdiscountAmt(discountAmt - parseFloat(values.loyaltyPoint));
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleApplyCoupon(e);
    }
  };
  return {
    handleInputChange,
    values,
    setValues,
    handleAddingTip,
    handleApplyCoupon,
    couponData,
    handleRemoveCoupon,
    handleKeyDown,
    orderSummaryData,
    handleStoreCredit,
    handleUseStoreCredit,
    handleRemoveStoreCredit,
    handleInputLoyaltyPoint,
    handleUseLoyaltyPoint,
    handleRemoveLoyaltyPoint,
    amountDeducCoupon,
    discountAmt,
  };
};

export default CouponTipSectionLogic;
