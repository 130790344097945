import LoyaltyPointIcon from '../../../Assets/OrderScreen/LoyaltyPointIcon.svg';
import React, { useEffect, useState } from 'react';
import { fetchStoreCreditData } from '../../../redux/features/StoreCreditList/scHistorySlice';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import EmptyPoints from '../../../Assets/OrderScreen/EmptyPoints.svg';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dropdown from '../../../Assets/Payment/dropdown.svg';

const StoreCreditZone = ({ mymerchantId, orderMethod }) => {
  const Navigate = useNavigate();
  const [allschistory, setallschistory] = useState([]);

  const [filterType, setFilterType] = useState('recent'); // Default filter type is 'month'
  const AllScHistoryDataState = useSelector((state) => state.storecreditlist);
  const authUserData = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const merchantId = location.pathname.replace('/Store-Credit-Zone/', '');
  // const orderMethod = searchParams.get('orderMethod');
  let merchant_store_id = localStorage.getItem('merchant_id');
  let store_method_id = localStorage.getItem('orderRecord');

  useEffect(() => {
    // console.log('hehehhe');
    const scdata = {
      // "customer_id": "27926",
      // "merchant_id": "MAL0100CA",
      customer_id:
        authUserData.isAuthenticated &&
        authUserData.UserData &&
        authUserData.UserData.id
          ? authUserData.UserData.id
          : '',
      merchant_id: mymerchantId ? mymerchantId : '',
    };

    if (
      authUserData.isAuthenticated &&
      authUserData.UserData !== null &&
      authUserData.UserData.id &&
      mymerchantId
    ) {
      dispatch(fetchStoreCreditData(scdata));
    }
  }, [authUserData, authUserData.isAuthenticated]);

  useEffect(() => {
    if (!AllScHistoryDataState.loading && AllScHistoryDataState.schistoryData) {
      setallschistory(AllScHistoryDataState.schistoryData);
      // console.log(AllScHistoryDataState.schistoryData)
    }
  }, [
    AllScHistoryDataState,
    AllScHistoryDataState.loading,
    AllScHistoryDataState.schistoryData,
  ]);

  const handleOrderSummery = (orderId) => {
    // Navigate(`/orderSummeryPage`, {
    //   state: {
    //     merchantId: merchant_store_id,
    //     orderId: orderId,
    //     orderMethod: orderMethod,
    //   },
    // });
  };
  useEffect(() => {
    const filterData = () => {
      const currentDate = new Date();
      if (AllScHistoryDataState.loyaltyPointData) {
        const filteredData =
          AllScHistoryDataState.loyaltyPointData.sc_data.filter(
            (loyaltyItem) => {
              const itemDate = new Date(loyaltyItem.created_on);
              // Calculate the difference in months between the current date and the loyalty point creation date
              const monthsDifference =
                (currentDate.getFullYear() - itemDate.getFullYear()) * 12 +
                currentDate.getMonth() -
                itemDate.getMonth();
              // Return true if the loyalty point was created within the last three months
              return monthsDifference <= 3;
            },
          );
        setallschistory((prevState) => ({
          ...prevState,
          sc_data: filteredData,
        }));
      }
    };
  });

  const filteredLoyaltyPointData =
    allschistory.sc_data && Array.isArray(allschistory.sc_data)
      ? allschistory.sc_data.filter((StoreCredithistorylist) => {
          const currentDate = new Date();
          const itemDate = new Date(StoreCredithistorylist.created_on);
          switch (filterType) {
            case 'month':
              return (
                currentDate.getMonth() - itemDate.getMonth() < 3 &&
                currentDate.getYear() === itemDate.getYear()
              );
            case 'year':
              return (
                currentDate.getFullYear() === itemDate.getFullYear() &&
                currentDate.getMonth() >= itemDate.getMonth()
              );
            case 'recent':
            default:
              return (
                currentDate.getMonth() === itemDate.getMonth() &&
                currentDate.getFullYear() === itemDate.getFullYear()
              );
          }
        })
      : [];

  // const handleFilterChange = (event) => {
  //   setFilterType(event.target.value);
  // };
  // console.log(filteredLoyaltyPointData);

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('recent');

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setShowDropdown(false);
    setFilterType(filter);
  };

  const filters = [
    { value: 'month', label: 'Last 3 Months' },
    { value: 'year', label: 'Last 1 Year' },
    { value: 'recent', label: 'Recent' },
  ];

  return (
    <>
      {!authUserData.isAuthenticated ? (
        <div className="single-store-empty-wishlist">
          <div className="empty-wishlist-content">
            <img src={EmptyPoints} alt="No-products" />
            <h1>PLEASE LOG IN</h1>
            <p>Login to view Store Credit.</p>
            <Link to="/customer-login" state={{ merchantId: mymerchantId }}>
              {' '}
              <Button className="continue-shopping-on-wishlist">
                {' '}
                Login{' '}
              </Button>{' '}
            </Link>
          </div>
        </div>
      ) : (
        <div className="StoreCredit-point-zone-page">
          <div className="loyalty-point-zone-banner">
            <div className="loyalty-point-zone-top-detail">
              <p>
                {' '}
                <img src={LoyaltyPointIcon} alt="points" />{' '}
                <span>Store Credit</span>{' '}
              </p>
              <span>
                {allschistory.total_sc_points > 0
                  ? allschistory.total_sc_points
                  : '0.00'}
              </span>
            </div>
          </div>
          {allschistory &&
          allschistory.sc_data &&
          allschistory.sc_data.length >= 1 ? (
            <div className="StoreCredit-zone-detail-section">
              <div className="loyalty-details-table">
                <p>Points Activity</p>
                <p>
                  {/* <select className="loyalty-customSelect ">
                                            <option value="month">Last 3 months</option>
                                            <option value="years">Last 1 year</option>
                                            <option value="recen">Recent</option>
                                          
                                        </select> */}
                  {/* <div className="custom-select">
                    <div
                      className="default-option"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      <span className='mx-2'>
                        {filters.find(
                          (filter) => filter.value === selectedFilter,
                        )?.label || 'Select Filter'}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //   xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      >
                        <defs>
                          <clipPath id="clipPath">
                            <rect
                              id="Rectangle_3424"
                              data-name="Rectangle 3424"
                              width="28"
                              height="28"
                              transform="translate(1211 -3447)"
                              fill="none"
                              stroke="#707070"
                              stroke-width="1"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Mask_Group_5972"
                          data-name="Mask Group 5972"
                          transform="translate(-1211 3447)"
                          clipPath="url(#clipPath)"
                        >
                          <g
                            id="Group_8217"
                            data-name="Group 8217"
                            transform="translate(-138.3 -1112.558)"
                          >
                            <line
                              id="Line_587"
                              data-name="Line 587"
                              x2="19.34"
                              transform="translate(1352 -2328.5)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_588"
                              data-name="Line 588"
                              x2="10.744"
                              transform="translate(1352 -2320.979)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_589"
                              data-name="Line 589"
                              x2="6.447"
                              transform="translate(1352 -2313.458)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_590"
                              data-name="Line 590"
                              y2="10.744"
                              transform="translate(1370.265 -2323.128)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              stroke-width="2"
                            />
                            <path
                              id="Path_9210"
                              data-name="Path 9210"
                              d="M1364-2331.429l4.839,4.839,4.839-4.839"
                              transform="translate(1.421 14.206)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              stroke-width="2"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    {showDropdown && (
                      <ul className="select-ul">
                        {filters.map((filter) => (
                          <li
                            key={filter.value}
                            onClick={() => handleFilterChange(filter.value)}
                          >
                            {filter.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div> */}
                </p>
              </div>

              {allschistory.sc_data.map((StoreCredithistorylist, index) => (
                <div
                  key={index}
                  onClick={() =>
                    handleOrderSummery(StoreCredithistorylist?.reason)
                  }
                >
                  <div className="loyalty-order-details">
                    <div className="loyalty-order-point">
                      Order ID: {StoreCredithistorylist.reason}
                      <p className="loyalty-order-id">
                        Added on: {StoreCredithistorylist.created_on}
                      </p>
                    </div>
                    <div className="loyalty-point-increament">
                      +{StoreCredithistorylist.credit_amount} Points
                      <p className="loyalty-pont-decrement">
                        -{StoreCredithistorylist.debit_amount} Points
                      </p>
                    </div>
                    {/* <div className="loyalty-order-deatils">Order Details</div> */}
                  </div>
                  <div className="loyalty-border-line"></div>
                </div>
              ))}
            </div>
          ) : (
            <div className="single-store-empty-loyalty-point">
              <div className="empty-loyalty-point-content">
                <img src={EmptyPoints} alt="No-products" />
                <h1>You don't have any Store Credit Points</h1>
                <p>Start Earning your Points today</p>{' '}
                <Link
                  to={
                    merchant_store_id && store_method_id
                      ? `/merchant/${merchant_store_id}?orderMethod=${store_method_id}`
                      : '/'
                  }
                >
                  {' '}
                  <Button className="continue-shopping-on-loyalty-point">
                    {' '}
                    Start Shopping{' '}
                  </Button>{' '}
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StoreCreditZone;
