import React, { useState, useEffect } from 'react'
import SingleCloseOrder from './SingleCloseOrder'
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { BASE_URL_SANDBOX, GET_ALL_CLOSE_ORDERS } from '../../../Constants/Config';
import { useSelector } from 'react-redux';
import NoorderImage from "../../../Assets/OrderScreen/NoOrders.svg"
import { Button, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const CloseOrders = ({ mymerchantId,orderMethod }) => {

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [dataSource, setdataSource] = useState(Array.from({ length: 6 }));
  const [hasMore, setHasMore] = useState(true);
  const [allcloseorders, setAllCloseOrders] = useState([])
  const authUserData = useSelector((state) => state.authUser)
  const fectchAllSingleOrder = async () => {
    try {
      const data = {
        "id": authUserData.UserData.id
      }
      const response = await axios.post(BASE_URL_SANDBOX + GET_ALL_CLOSE_ORDERS, data, { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.data.status === 200) {
        setAllCloseOrders(response.data.message);
        setIsLoading(false);
      }
      else {
        setAllCloseOrders(false)
        setHasMore(false)
        setIsLoading(false);
      }
    }


    catch (error) {
      return new Error(error);
    }
  }

  const fetchData = async () => {
    await fectchAllSingleOrder()
    const orderLength = allcloseorders && allcloseorders.length
    // console.log(orderLength)
    if (dataSource.length < orderLength) {
      setTimeout(() => {
        setdataSource(dataSource.concat(Array.from({ length: 5 })))
      }, 1000);
    }
    else {
      setHasMore(false)
    }
  }
  useEffect(() => {
    fectchAllSingleOrder()

  }, [authUserData])


  return (

    <>
      <InfiniteScroll dataLength={dataSource.length} next={fetchData}
        hasMore={hasMore}
        // loader={allcloseorders && allcloseorders.length > 0 ? <center style={{ width: "100px", height: "100px", margin: "10px" }}><CircularProgress color="inherit" /></center> : ""}

        loader={allcloseorders && allcloseorders.length >= 1 ? "" : ""}

        endMessage={
          hasMore ?
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p> : ""
        }>

        <Grid container justifyContent={"start"} spacing={2}>
          {allcloseorders && allcloseorders.length >= 1 ? allcloseorders.map((order, index) => {
            return <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={order.id}>{isLoading ? (<h1>Loading...</h1>) : (<SingleCloseOrder Order={order} orderMethod={orderMethod} />)} </Grid>
          })
            : (
              <>
                {isLoading ? (<div className='no-open-and-close-order'>
                  <h1> Loading...</h1>
                </div>) : (
                  <div className='no-open-and-close-order'>
                    <img src={NoorderImage} alt="No-orders" />
                    <h1>NO ORDER HISTORY</h1>
                    <p className='mb-3'>You Don't Have any orders in your History</p>
                    <Link to={mymerchantId ? `/merchant/${mymerchantId}?orderMethod=${orderMethod}` : "/"} > <Button className='start-shopping-on-order'> Start Shopping </Button>  </Link>
                  </div>

                )}
              </>
            )

          }
        </Grid>


      </InfiniteScroll>
    </>
  )
}

export default CloseOrders
