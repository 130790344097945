import { useState, useEffect } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { useNavigate } from 'react-router-dom';
import {
  BASE_URL_SANDBOX,
  CHECK_ADDRESS_IN_RANGE,
  UPDATE_DELIVERY_ADDRESS,
} from '../../../Constants/Config';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAccoutInfoData } from '../../../redux/features/Account/accountInfoSlice';
import { addAddress } from '../../../redux/features/Account/deliveryAddressSlice'; /////dispatch(addAddress)

const EditDeliveryAddressLogic = ({
  handleClose,
  address,
  checkradius,
  setDeliveryAddress,
  handleSelectAddress,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isGuest } = useSelector((state) => state.authUser);
  const {
    validateFirstName,
    validateLastName,
    validateStreetAddress,
    validateZipCode,
    validateCity,
    validateState,
    validateApartment,
    validatePhoneNumber,
  } = Validation();
  const Navigate = useNavigate();
  const [submitmessage, setsubmitmessage] = useState('');

  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const Space = /^[^\s]+$/i;
  const PhoneNo = /^\d{10}$/;
  const Nameval = /^[a-zA-Z]+$/;
  const Numberval = /^\d*$/;
  const Characterval = /^[A-Za-z\s]+$/;

  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    streetaddress: '',
    apartmentnumber: '',
    city: '',
    state: '',
    zipcode: '',
    // cursorposition: {
    //   firstname: 0,
    //   lastname: 0,
    //   phone: 0,
    //   streetaddress: 0,
    //   apartmentnumber: 0,
    //   city: 0,
    //   zipcode: 0,
    // },
    errors: {
      firstname: '',
      lastname: '',
      phone: '',
      streetaddress: '',
      apartmentnumber: '',
      city: '',
      state: '',
      zipcode: '',
    },
  });
  const storeDataState = useSelector((state) => state.singleStores);
  const authUserData = useSelector((state) => state.authUser);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      firstname:
        isGuest !== 'yes'
          ? address && address.d_f_name
            ? address.d_f_name
            : ''
          : address && address?.d_f_name
          ? address?.d_f_name
          : '',
      lastname:
        isGuest !== 'yes'
          ? address && address.d_l_name
            ? address.d_l_name
            : ''
          : address && address?.d_l_name
          ? address?.d_l_name
          : '',
      phone:
        isGuest !== 'yes'
          ? address && address.d_mobile
            ? address.d_mobile
            : ''
          : address && address?.d_mobile
          ? address?.d_mobile
          : '',
      streetaddress:
        isGuest !== 'yes'
          ? address && address.a_address_line_1
            ? address.a_address_line_1
            : ''
          : address && address?.a_address_line_1
          ? address?.a_address_line_1
          : '',
      apartmentnumber:
        isGuest !== 'yes'
          ? address && address.a_address_line_2
            ? address.a_address_line_2
            : ''
          : address && address?.a_address_line_2
          ? address?.a_address_line_2
          : '',
      city:
        isGuest !== 'yes'
          ? address && address.a_city
            ? address.a_city
            : ''
          : address && address?.a_city
          ? address?.a_city
          : '',
      state:
        isGuest !== 'yes'
          ? address && address.a_state
            ? address.a_state
            : ''
          : address && address?.a_state
          ? address?.a_state
          : '',
      zipcode:
        isGuest !== 'yes'
          ? address && address.a_zip
            ? address.a_zip
            : ''
          : address && address?.a_zip
          ? address?.a_zip
          : '',
    }));
  }, []);
  const AddAddressApi = async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + UPDATE_DELIVERY_ADDRESS,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      if (response.data.status && response.data.status === 200) {
        let fetchdata = {
          id: authUserData.UserData.id,
          merchant_id: checkradius
            ? storeDataState?.storesData?.store_data?.merchant_id
            : '',
        };
        if (isGuest === 'yes') {
          data.is_guest = 'yes';
        }
        dispatch(fetchAccoutInfoData(fetchdata));
        handleClose();

        let addressPayload = '';
        addressPayload = {
          merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
          cc_address: data.cc_street_address,
          cc_address_2: data.suite_address,
          cc_city: data.city,
          cc_state: data.state,
          cc_zip: data.cc_zip_code,
        };
        const RadiusCheckEditdata = await axios.post(
          BASE_URL_SANDBOX + CHECK_ADDRESS_IN_RANGE,
          addressPayload,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (RadiusCheckEditdata.data.status === 200) {
          let mydata = {
            radiusData: RadiusCheckEditdata.data.result || {},
            address: {
              ...data,
              d_f_name: data.cc_f_name,
              d_l_name: data.cc_l_name,
              d_mobile: data.cc_phone_no,
              a_address_line_1: data.cc_street_address,
              a_address_line_2: data.suite_address,
              a_city: data.city,
              a_state: data.state,
              a_zip: data.cc_zip_code,
              id: data.Id,
            },
            billing: 'no',
          };
          setDeliveryAddress(mydata);
          handleSelectAddress(mydata);
        }

        // setsubmitmessage('');
      } else {
        setsubmitmessage(response.data.message);
      }
    } catch (error) {
      return new Error(error);
    }
  };
  const CheckAddressRadiusApi = async (data) => {
    try {
      const response = await axios.post(
        BASE_URL_SANDBOX + CHECK_ADDRESS_IN_RANGE,
        data,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      // console.log(response);
      if (response?.data?.status === 200) {
        if (isGuest === 'yes') {
          localStorage.setItem(
            'radius',
            JSON.stringify(response?.data?.result),
          );
        }
        return response.data.status === 200;
      } else {
        // console.log('response: ', response);
        // setsubmitmessage('This address is out of delivery radius.');
        setsubmitmessage(response?.data?.message);
      }
    } catch (error) {
      return false;
    }
  };

  const DeliveryAddressInput = async (event) => {
    let { errors } = values;
    let { cursorposition } = values;
    const newCursorPosition = event.target.selectionStart;
    const fieldName = event.target.name;
    const fieldValue =
      fieldName === 'phone' || fieldName === 'zipcode'
        ? event.target.value.replace(/[^0-9]/g, '')
        : event.target.value;
    switch (fieldName) {
      case 'firstname':
        // cursorposition.firstname = newCursorPosition;
        // await validateFirstName(fieldValue, errors);
        if (fieldName === '') {
          errors.firstname = 'Please enter FirstName ';
        } else if (emoji.test(fieldName)) {
          errors.firstname = 'Emoji not allowed';
        } else if (!Space.test(fieldName)) {
          errors.firstname = 'Space is not allow';
        } else if (!Nameval.test(fieldName)) {
          errors.firstname = 'Alphabets only';
        } else {
          errors.firstname = '';
        }
        break;
      case 'lastname':
        // cursorposition.lastname = newCursorPosition;
        // await validateLastName(fieldValue, errors);
        if (fieldValue === '') {
          errors.lastname = 'Please enter LastName ';
        } else if (emoji.test(fieldValue)) {
          errors.lastname = 'Emoji not allowed';
        } else if (!Space.test(fieldValue)) {
          errors.lastname = 'Space is not allow';
        } else if (!Nameval.test(fieldValue)) {
          errors.lastname = 'Alphabets only';
        } else {
          errors.lastname = '';
        }
        break;
      case 'phone':
        // cursorposition.phone = newCursorPosition;
        // await validatePhoneNumber(fieldValue, errors);
        if (fieldValue === '') {
          errors.phone = 'Please enter phone number';
        } else if (emoji.test(fieldValue)) {
          errors.phone = 'Emoji not allowed';
        } else if (!PhoneNo.test(fieldValue)) {
          errors.phone = 'Phone no not valid';
        } else {
          errors.phone = '';
        }
        break;
      case 'streetaddress':
        // cursorposition.streetaddress = newCursorPosition;
        // await validateStreetAddress(fieldValue, errors);
        if (fieldValue === '') {
          errors.streetaddress = 'Please enter street address ';
        } else if (emoji.test(fieldValue)) {
          errors.streetaddress = 'Emoji not allowed';
        } else {
          errors.streetaddress = '';
        }
        break;
      case 'apartmentnumber':
        // cursorposition.apartmentnumber = newCursorPosition;
        // await validateApartment(fieldValue, errors);
        if (fieldValue === '') {
          errors.apartmentnumber = 'Please enter Suite/Apartment Number ';
        } else if (emoji.test(fieldValue)) {
          errors.apartmentnumber = 'Emoji not allowed';
        } else {
          errors.apartmentnumber = '';
        }
        break;
      case 'city':
        // cursorposition.city = newCursorPosition;
        // await validateCity(fieldValue, errors);
        if (fieldValue === '') {
          errors.city = 'Please enter city ';
        } else if (emoji.test(fieldValue)) {
          errors.city = 'Emoji not allowed';
        } else if (!Characterval.test(fieldValue)) {
          errors.city = 'City  contain character';
        } else {
          errors.city = '';
        }
        break;
      case 'state':
        await validateState(fieldValue, errors);
        break;
      case 'zipcode':
        // cursorposition.zipcode = newCursorPosition;
        // await validateZipCode(fieldValue, errors);
        if (fieldValue === '') {
          errors.zipcode = 'Please enter zipcode  ';
        } else if (emoji.test(fieldValue)) {
          errors.zipcode = 'Emoji not allowed';
        } else if (fieldValue?.length !== 5) {
          errors.zipcode = 'Max 5 number enter';
        } else if (!Numberval.test(fieldValue)) {
          errors.zipcode = 'Zipcode contain number ';
        } else {
          errors.zipcode = '';
        }
        break;

      default:
        break;
    }
    setValues((prevValues) => ({
      errors,
      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };

  let defaultsetAddress = (data) => {
    setTimeout(() => {
      // console.log('hi dev...');
      setDeliveryAddress(data);
    }, 500);
  };

  const handleSubmitDeliveryAddress = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let { errors } = values;
    validateFirstName(values.firstname, errors);
    validateLastName(values.lastname, errors);
    validatePhoneNumber(values.phone, errors);
    validateCity(values.city, errors);
    validateStreetAddress(values.streetaddress, errors);
    validateState(values.state, errors);
    // validateApartment(values.apartmentnumber, errors);
    validateZipCode(values.zipcode, errors);
    if (
      errors.firstname === '' &&
      errors.lastname === '' &&
      errors.state === '' &&
      errors.city === '' &&
      errors.zipcode === '' &&
      errors.streetaddress === '' &&
      errors.phone === ''
      // && errors.apartmentnumber === ''
    ) {
      const data = {
        userId: authUserData.UserData.id,
        cc_f_name: values.firstname,
        cc_l_name: values.lastname,
        cc_street_address: values.streetaddress,
        cc_zip_code: values.zipcode,
        city: values.city,
        cc_phone_no: values.phone,
        suite_address: values.apartmentnumber,
        state: values.state,
        Id: address.id,
      };
      const dataForRadius = {
        merchant_id: storeDataState?.storesData?.store_data?.merchant_id,
        cc_address: values.streetaddress,
        cc_address_2: values.apartmentnumber,
        cc_city: values.city,
        cc_state: values.state,
        cc_zip: values.zipcode,
      };

      if (checkradius) {
        const inRange = await CheckAddressRadiusApi(dataForRadius);
        if (isGuest !== 'yes') {
          if (inRange) {
            await AddAddressApi(data);
          }
        } else {
          if (inRange) {
            let guestAddressData = {
              // radiusData: radiusData || {},
              radiusData: JSON.parse(localStorage.getItem('radius')),
              address: {
                d_f_name: values.firstname,
                d_l_name: values.lastname,
                d_mobile: values.phone,
                a_address_line_1: values.streetaddress,
                a_address_line_2: values.apartmentnumber,
                a_city: values.city,
                a_state: values.state,
                a_zip: values.zipcode,
                id: authUserData.UserData.id,
              },
              billing: 'no',
            };
            const jsonData = JSON.stringify(guestAddressData);
            localStorage.setItem('guestAddDeliveryAddress', jsonData);
            handleClose();
            defaultsetAddress(guestAddressData);
            dispatch(addAddress([]));
            dispatch(addAddress([guestAddressData]));
          }
        }
      } else {
        await AddAddressApi(data);
      }
    }
    setIsLoading(false);
    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  const handlekeydown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitDeliveryAddress(e);
    }
  };

  return {
    DeliveryAddressInput,
    handleSubmitDeliveryAddress,
    values,
    submitmessage,
    setsubmitmessage,
    handlekeydown,
    isLoading,
  };
};

export default EditDeliveryAddressLogic;
