import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@mui/material';
import BillingInfoLogic from './BillingInfoLogic';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker, DatePicker } from '@mui/x-date-pickers';
import ImageCropModal from './ImageCropModal';
import dayjs from 'dayjs';
import { BASE_URL_SANDBOX } from '../../../Constants/Config';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const BillingInfo = ({ userData }) => {
  const inputFileRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [inputFileClass, setInputFileClass] = useState(
    'file-input-not-display',
  );
  const [selectedImage, setSelectedImage] = useState();
  const [oldSelectedImage, setOldSelectedImage] = useState();
  const inputRefs = useRef({});
  const [selectedImageBase64, setSelectedImageBase64] = useState('');
  const [imageName, setImageName] = useState('');
  const StateList = useSelector((state) => state?.allStatesData?.allStateList);
  const handleOpen = () => {
    // setSelectedImage(values.myfile)
    setOpen(true);
  };
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleClose = () => {
    if (selectedImage) setSelectedImage(null);
    if (oldSelectedImage) setOldSelectedImage(null);
    setOpen(false);
    values.errors.firstname = '';
    values.errors.lastname = '';
    values.errors.state = '';
    values.errors.city = '';
    values.errors.phone = '';
    values.errors.streetaddress = '';
    values.errors.apartmentnumber = '';
    values.errors.city = '';
    values.errors.zipcode = '';
    values.errors.idproof = '';
    values.errors.DOB = '';
    values.errors.expiredate = '';
    values.errors.myfile = '';
  };

  const handleDateOfBirth = (e) => {
    setValues((prevState) => ({
      ...prevState,
      DOB: e ? dayjs(new Date(e)) : '',
      errors: {
        DOB: '',
      },
    }));
  };
  const handleExpireDate = (e) => {
    // console.log(e)
    setValues((prevState) => ({
      ...prevState,
      expiredate: dayjs(new Date(e)),
      errors: {
        expiredate: '',
      },
    }));
  };

  const handleInputCHange = (e) => {
    handleFileInput(e);
    inputFileRef.current.value = '';
  };
  const handleFileInput = (e) => {
    setSelectedImage(null);
    setInputFileClass('file-input-not-display');
    const inputValue = e.target.files[0];
    // console.log(inputValue)
    // const newInputClass = inputValue ? 'file-input-not-display ' : 'my-custom-class-for-file-input';
    // setInputFileClass(newInputClass);
    if (inputValue !== undefined && inputValue !== null) {
      setSelectedImage(inputValue);
    }
  };
  const handleSelectImage = () => {
    setSelectedImage(null);
    let c = document.getElementById('image-input');
    c.click();
  };
  const {
    BillingInfoInput,
    handleSubmitBillingInfo,
    values,
    submitmessage,
    setValues,
    loading,
  } = BillingInfoLogic({
    handleClose,
    userData,
    selectedImageBase64,
    setSelectedImageBase64,
  });

  useEffect(() => {
    if (
      values.myfile === '' &&
      userData?.i_card_front_img &&
      !selectedImageBase64
    ) {
      setValues((prev) => ({ ...prev, myfile: userData?.i_card_front_img }));
    }
  }, [values, userData, selectedImageBase64]);

  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      myfile: imageName,
      errors: {
        myfile: '',
      },
    }));
  }, [selectedImage, imageName]);
  const yesterday = dayjs().subtract(1, 'day');

  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(
          values.cursorposition[fieldName],
          values.cursorposition[fieldName],
        );
      }
    }
  }, [values]);

  // console.log('UdateData Message ', loading);
  //for croppedImage
  const handleImageCropped = (croppedImage) => {
    // Update state with the cropped image
    setSelectedImage(croppedImage);
  };

  return (
    <>
      <div>
        {userData && userData.a_address_line_1 ? (
          <div
            style={{ cursor: 'pointer' }}
            className="modal-main-button BasicInfo-btn-padding"
            onClick={handleOpen}
          >
            Edit
          </div>
        ) : (
          <Button className="modal-main-button" onClick={handleOpen}>
            Add
          </Button>
        )}
        {isMobile}
        <Modal
          className="for-scroll"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal">
            <div id="modal-modal-title">
              <span>Billing Info</span>
              <CloseIcon onClick={handleClose} className="modal-close-icon" />
            </div>

            <form
              id="modal-modal-description"
              className="modal-forms new__input modal-Billinginfo"
            >
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                    // paddingLeft: '0',
                  }}
                >
                  <div>
                    <FormControl fullWidth>
                      <TextField
                        label="Street Address"
                        className="input-field"
                        variant="outlined"
                        //  size="small"
                        autoComplete="off"
                        name="streetaddress"
                        onChange={BillingInfoInput}
                        value={values.streetaddress}
                        // inputProps={{
                        //   'data-field': 'streetaddress',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['streetaddress'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.streetaddress,
                        // }}
                      />
                    </FormControl>
                    <span className="input-error">
                      {values.errors.streetaddress}
                    </span>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                    // paddingRight: '0',
                  }}
                >
                  <div>
                    <FormControl fullWidth>
                      <TextField
                        label=" Suite/Apartment Number"
                        className="input-field"
                        variant="outlined"
                        // size="small"
                        autoComplete="off"
                        name="apartmentnumber"
                        onChange={BillingInfoInput}
                        value={values.apartmentnumber}
                        // inputProps={{
                        //   'data-field': 'firstname',
                        //   autoComplete: 'off',
                        //   ref: (input) =>
                        //     (inputRefs.current['apartmentnumber'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.apartmentnumber,
                        // }}
                      />
                    </FormControl>
                    <span className="input-error">
                      {/* {values.errors.apartmentnumber} */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-12 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                    // paddingLeft: '0',
                  }}
                >
                  <div>
                    <FormControl fullWidth>
                      <TextField
                        label="City"
                        className="input-field"
                        variant="outlined"
                        autoComplete="off"
                        name="city"
                        onChange={BillingInfoInput}
                        value={values.city}
                        // inputProps={{
                        //   'data-field': 'firstname',
                        //   autoComplete: 'off',
                        //   ref: (input) => (inputRefs.current['city'] = input), // Store the ref in a ref object
                        //   selectionstart: values.cursorposition.city,
                        // }}
                      />
                    </FormControl>
                    <span className="input-error">{values.errors.city}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <div className="row">
                    <div
                      className="col-xs-6 col-sm-6 col-md-6"
                      style={{
                        position: 'relative',
                        marginBottom: '24px',
                      }}
                    >
                      <div>
                        <FormControl
                          className="form-select-formcontrol"
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-label modal-modal-description">
                            State
                          </InputLabel>
                          <Select
                            className="select-OnFocus state_control_height"
                            labelId="demo-simple-select-label"
                            // className="modal-form-single-input the-state-input-in-delivery id-type-billinginfo"
                            id="demo-simple-select"
                            label="State"
                            name="state"
                            scrollId=""
                            onChange={BillingInfoInput}
                            value={values.state}
                          >
                            {Array.isArray(StateList) &&
                              StateList.length >= 1 &&
                              StateList.map((item, index) => (
                                <MenuItem key={index} value={item.State}>
                                  {item.State}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <span className="input-error">
                          {values.errors.state}
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-xs-6 col-sm-6 col-md-6"
                      style={{
                        position: 'relative',
                        marginBottom: '24px',
                        // paddingRight: '0',
                      }}
                    >
                      <FormControl fullWidth>
                        <TextField
                          label="Zip Code"
                          className="input-field"
                          variant="outlined"
                          autoComplete="off"
                          name="zipcode"
                          onChange={BillingInfoInput}
                          value={values.zipcode}
                          inputProps={{
                            maxLength: 5,
                            // 'data-field': 'zipcode',
                            // autoComplete: 'off',
                            // ref: (input) =>
                            //   (inputRefs.current['zipcode'] = input), // Store the ref in a ref object
                            // selectionstart: values.cursorposition.zipcode,
                          }}
                        />
                      </FormControl>
                      <span className="input-error">
                        {values.errors.zipcode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <div>
                    <FormControl className="form-select-formcontrol" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        ID Type
                      </InputLabel>
                      <Select
                        className="select-OnFocus"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Id Type"
                        name="idproof"
                        onChange={BillingInfoInput}
                        value={values.idproof}
                      >
                        <MenuItem value="identification_card">
                          Identification Card
                        </MenuItem>
                        <MenuItem value="passport">Passport</MenuItem>
                        <MenuItem value="driving_license">
                          Driver's License
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <span className="input-error">{values.errors.idproof}</span>
                </div>

                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                    // paddingRight: '0',
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="ID Number"
                      className="input-field"
                      variant="outlined"
                      autoComplete="off"
                      name="idnumber"
                      onChange={BillingInfoInput}
                      value={values.idnumber}
                      // inputProps={{
                      //   'data-field': 'firstname',
                      //   autoComplete: 'off',
                      //   ref: (input) => (inputRefs.current['idnumber'] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.idnumber,
                      // }}
                    />
                    <span className="input-error">
                      {values.errors.idnumber}
                    </span>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <MobileDatePicker
                          defaultValue={
                            values.expiredate && dayjs(values.expiredate)
                          }
                          format={'MM/DD/YYYY'}
                          label="Expiration Date"
                          slotProps={{ textField: { variant: 'outlined' } }}
                          className="custom-date-picker-width"
                          onChange={(e) => handleExpireDate(e)}
                          name="expiredate"
                          disablePast
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <span className="input-error">
                      {values.errors.expiredate}
                    </span>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-md-6"
                  style={{
                    position: 'relative',
                    marginBottom: '24px',
                  }}
                >
                  <LocalizationProvider rovider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      {values && values.DOB ? (
                        <MobileDatePicker
                          // defaultValue={values.DOB && dayjs(values.DOB)}
                          value={values.DOB && dayjs(values.DOB)}
                          format={'MM/DD/YYYY'}
                          label="Date of Birth"
                          slotProps={{ textField: { variant: 'outlined' } }}
                          // className="modal-form-single-input "
                          className="custom-date-picker-width "
                          onChange={(e) => handleDateOfBirth(e)}
                          name="DOB"
                          disableFuture
                        />
                      ) : (
                        <MobileDatePicker
                          defaultValue={values.DOB && dayjs(values.DOB)}
                          format={'MM/DD/YYYY'}
                          label="Date of Birth"
                          slotProps={{ textField: { variant: 'outlined' } }}
                          // className="modal-form-single-input "
                          className="custom-date-picker-width "
                          onChange={(e) => handleDateOfBirth(e)}
                          name="DOB"
                          disableFuture
                        />
                      )}
                    </DemoContainer>
                  </LocalizationProvider>

                  <span className="input-error">{values.errors.DOB}</span>
                </div>
              </div>
              <FormControl>
                <div className="modal-form-single-item">
                  <div className="custom-file-upload-input">
                    <div className="image-input-quickvee">
                      <input
                        type="file"
                        className={` ${inputFileClass}`}
                        id="image-input"
                        onChange={(e) => handleInputCHange(e)}
                        style={{ display: 'none' }}
                        accept="image/*"
                        ref={inputFileRef}
                      />
                      {userData && userData.i_card_front_img ? (
                        <label
                          // htmlFor="image-input"
                          className="my-file-custom-input-for-image"
                        >
                          {' '}
                          {userData && userData.i_card_front_img
                            ? userData.i_card_front_img.length > 47
                              ? `${userData.i_card_front_img.slice(0, 32)}...`
                              : userData.i_card_front_img
                            : ''}{' '}
                        </label>
                      ) : (
                        <label
                          // htmlFor="image-input"
                          className="my-file-custom-input-for-image"
                        >
                          {' '}
                          {imageName ? imageName : 'Upload Document Here'}{' '}
                        </label>
                      )}

                      <ImageCropModal
                        userData={userData && userData}
                        handleSelectImage={handleSelectImage}
                        selectedImage={selectedImage}
                        setOldSelectedImage={setOldSelectedImage}
                        setSelectedImage={setSelectedImage}
                        setImageName={setImageName}
                        setSelectedImageBase64={setSelectedImageBase64}
                        payment={'account'}
                        onImageCropped={handleImageCropped} // for croppedImage
                      />
                    </div>
                    {selectedImage || (userData && userData.image_url) ? (
                      <center>
                        <img
                          src={
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : oldSelectedImage
                              ? URL.createObjectURL(oldSelectedImage)
                              : userData?.image_url
                          }
                          alt="Selected"
                          style={{
                            width: '250px',
                            height: '150px',
                            objectFit: 'cover',
                            margin: '12px 0px',
                          }}
                        />
                      </center>
                    ) : (
                      ''
                    )}
                  </div>
                  <span style={{ left: '0' }} className="input-error">
                    {values.errors.myfile}
                  </span>
                </div>
              </FormControl>

              <div style={{ margin: '0.25rem auto' }}>
                {loading === 'Please wait while the Data Update.' && (
                  <>
                    <CircularProgress />
                    <span
                      className="input-error"
                      style={{ position: 'relative', top: '-1rem' }}
                    >
                      {loading}
                    </span>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <Button className="modal-cancle-btn" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  className="modal-save-btn"
                  onClick={handleSubmitBillingInfo}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default BillingInfo;
