import React, { useState, useEffect } from 'react'
import SingleOpenOrder from './SingleOpenOrder'
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { BASE_URL_SANDBOX, GET_ALL_OPEN_ORDERS } from '../../../Constants/Config';
import { useSelector } from 'react-redux';
import NoorderImage from "../../../Assets/OrderScreen/NoOrders.svg"
import { Button, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const OpenOrders = ({ mymerchantId,orderMethod }) => {
  // console.log(orderMethod)
  const location = useLocation();
  const [dataSource, setdataSource] = useState(Array.from({ length: 6 }));
  const [hasMore, setHasMore] = useState(true);
  const [allopenorders, setAllOpenOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const authUserData = useSelector((state) => state.authUser)
  const fectchAllSingleOrder = async () => {
    try {
      const data = {
        "id": authUserData.UserData.id,
      }
      const response = await axios.post(BASE_URL_SANDBOX + GET_ALL_OPEN_ORDERS, data, { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.data.status === 200) {

        setAllOpenOrders(response.data.message);
        setIsLoading(false);
      }else{
        setAllOpenOrders(false)
        setHasMore(false)
        setIsLoading(false);
      }

      // console.log(response.data.message);

    } catch (error) {
      return new Error(error);
    }
  }

  const fetchData = async () => {
    await fectchAllSingleOrder()
    const orderLength = allopenorders && allopenorders.length
    // console.log(orderLength)
    if (dataSource.length < orderLength) {
      setTimeout(() => {
        setdataSource(dataSource.concat(Array.from({ length: 5 })))
      }, 1000);
    }
    else {
      setHasMore(false)
    }
  }
  useEffect(() => {
    fectchAllSingleOrder()
    }, [authUserData])




  return (

    <>
      <InfiniteScroll dataLength={dataSource.length} next={fetchData}
        hasMore={hasMore}
        loader={allopenorders & allopenorders.length >= 1 ? <center style={{ width: "100px", height: "100px", margin: "10px" }}><CircularProgress color="inherit" /></center> : ""}

        endMessage={hasMore ?
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p> : ""
        }>
        <Grid container justifyContent={"start"} spacing={2}>
          {allopenorders && allopenorders.length >= 1 ? allopenorders.map((order, index) => {
            return <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={order.id} >
              {isLoading ? (<h1>Loading...</h1>) : (<SingleOpenOrder Order={order} orderMethod={orderMethod} />)}


            </Grid>


          }) : (

            <>
              {isLoading ? (<div className='no-open-and-close-order'>
                <h1> Loading...</h1>
              </div>) : (
                <div className='no-open-and-close-order'>
                  <img src={NoorderImage} alt="No-orders" />
                  <h1>NO ORDER HISTORY</h1>
                  <p className='mb-3'>You Don't Have any orders in your History</p>
                  <Link to={mymerchantId ? `/merchant/${mymerchantId}?orderMethod=${orderMethod}` : "/"} > <Button className='start-shopping-on-order'> Start Shopping </Button>  </Link>
                </div>

              )}
            </>
          )


          }
        </Grid>


      </InfiniteScroll >

    </>
  )
}

export default OpenOrders